import styled from 'styled-components';

const StyledBasicText = styled.p`
  color: ${({ theme }) => theme.colors.textColor};
  line-height: normal;
`;

export const Text12Light = styled(StyledBasicText)`
  font-size: 1.2rem;
  font-weight: 300;
`;

export const Text13Light = styled(StyledBasicText)`
  font-size: 1.3rem;
  font-weight: 300;
`;

export const Text13Regular = styled(StyledBasicText)`
  font-size: 1.3rem;
  font-weight: 400;
`;

export const Text13Bold = styled(StyledBasicText)`
  font-size: 1.3rem;
  font-weight: 500;
`;

export const Text14Light = styled(StyledBasicText)`
  font-size: 1.4rem;
  font-weight: 300;
`;

export const Text14Regular = styled(StyledBasicText)`
  font-size: 1.4rem;
  font-weight: 400;
`;

export const Text14Bold = styled(StyledBasicText)`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const Text15Light = styled(StyledBasicText)`
  font-size: 1.5rem;
  font-weight: 300;
`;

export const Text15Regular = styled(StyledBasicText)`
  font-size: 1.5rem;
  font-weight: 400;
`;

export const Text15Bold = styled(StyledBasicText)`
  font-size: 1.5rem;
  font-weight: 500;
`;

export const Text16Regular = styled(StyledBasicText)`
  font-size: 1.6rem;
  font-weight: 400;
`;

export const Text16Bold = styled(StyledBasicText)`
  font-size: 1.6rem;
  font-weight: 500;
`;

export const Text17Regular = styled(StyledBasicText)`
  font-size: 1.7rem;
  font-weight: 400;
`;

export const Text17Bold = styled(StyledBasicText)`
  font-size: 1.7rem;
  font-weight: 500;
`;

export const Text18Light = styled(StyledBasicText)`
  font-size: 1.8rem;
  font-weight: 300;
`;

export const Text18Regular = styled(StyledBasicText)`
  font-size: 1.8rem;
  font-weight: 400;
`;

export const Text18Bold = styled(StyledBasicText)`
  font-size: 1.8rem;
  font-weight: 500;
`;

export const Text20Regular = styled(StyledBasicText)`
  font-size: 2rem;
  font-weight: 400;
`;

export const Text20Bold = styled(StyledBasicText)`
  font-size: 2rem;
  font-weight: 500;
`;

export const Text22Bold = styled(StyledBasicText)`
  font-size: 2.2rem;
  font-weight: 500;
`;
export const Text24Bold = styled(StyledBasicText)`
  font-size: 2.4rem;
  font-weight: 500;
`;

export const Text24ExtraBold = styled(StyledBasicText)`
  font-size: 2.4rem;
  font-weight: 600;
`;

export const Text26ExtraBold = styled(StyledBasicText)`
  font-size: 2.6rem;
  font-weight: 600;
`;

export const Text28ExtraBold = styled(StyledBasicText)`
  font-size: 2.8rem;
  font-weight: 600;
`;

export const Text32ExtraBold = styled(StyledBasicText)`
  font-size: 3.2rem;
  font-weight: 700;
`;

export const Text36Bold = styled(StyledBasicText)`
  font-size: 3.6rem;
  font-weight: 500;
`;

export const Text88Bold = styled(StyledBasicText)`
  font-size: 8.8rem;
  font-weight: 500;
`;
