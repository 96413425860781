import React from 'react';
import { InputContainer, NameText, ValueContainer } from './SingleFieldInput.styles';
import { Text15Light } from '../../utils/Text/Text.styles';

const SingleFieldInput = ({ name, value }) => {
  return (
    <InputContainer>
      <NameText>{name}</NameText>
      <ValueContainer>
        <Text15Light>{value}</Text15Light>
      </ValueContainer>
    </InputContainer>
  );
};

export default SingleFieldInput;
