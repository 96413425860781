import { VATS } from './vats';

export const getVatByCountry = country => {
  const vat = VATS[country];
  if (vat) {
    return vat;
  } else {
    return 0;
  }
};

export const roundNumber = (number, decimals = 2) =>
  +(Math.round((number + Number.EPSILON) * 100) / 100).toFixed(decimals);

export const getFileExtensionFromBase64 = base64String => {
  // Regular expression to extract the MIME type
  const mimeTypeMatch = base64String.match(/^data:([A-Za-z-+/]+);base64,/);
  if (!mimeTypeMatch || mimeTypeMatch.length !== 2) {
    throw new Error('Invalid base64 string');
  }

  const mimeType = mimeTypeMatch[1];

  // Map MIME type to file extension
  const mimeToExtension = {
    'image/jpg': 'jpg',
    'image/jpeg': 'jpg',
    'image/png': 'png',
  };

  const extension = mimeToExtension[mimeType];
  if (!extension) {
    throw new Error('Unsupported MIME type');
  }

  return [extension, mimeType];
};

export const base64ToFile = (base64String, fileNamePrefix) => {
  const [extension, mimeType] = getFileExtensionFromBase64(base64String);
  // Decode the base64 string
  const byteString = atob(base64String.split(',')[1]);

  // Create an array of byte values
  const byteNumbers = new Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }

  // Create a Uint8Array from the byte array
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob from the Uint8Array
  const blob = new Blob([byteArray], { type: mimeType }); // Adjust the MIME type as needed

  // Create a File from the Blob
  const file = new File([blob], `${fileNamePrefix}.${extension}`, { type: mimeType }); // Adjust the MIME type and file name as needed

  return file;
};
