import { createAsyncThunk } from '@reduxjs/toolkit';
import UserService from '../../../api/services/UserService';

export const fetchUserInfo = createAsyncThunk('user/fetchUserInfo', async () => {
  return await UserService.fetchUserInfo();
});

export const changePassword = createAsyncThunk('user/changePassword', async requestData => {
  return await UserService.changePassword(requestData);
});

export const fetchUserEarnings = createAsyncThunk('user/fetchUserEarnings', async requestData => {
  return await UserService.fetchUserEarnings(requestData);
});

export const handleWithdrawalMethods = createAsyncThunk('user/handleWithdrawalMethods', async requestData => {
  return await UserService.handleWithdrawalMethods(requestData);
});
