import React from 'react';
import { Card, CardContent, DollarSign, EarningContainer, EarningsContent, NetText } from './AnalyticsCard.styles';
import { Text16Bold, Text22Bold } from '../../utils/Text/Text.styles';
import { VALUE_OF_ONE_STAR_IN_USD } from '../../../utils/constants';

const AnalyticsCard = ({ earnings, icon, title }) => {
  return (
    <Card>
      {icon}
      <CardContent>
        <Text16Bold>{title}</Text16Bold>
        <EarningsContent>
          <EarningContainer>
            <NetText>Gross:</NetText>
            <DollarSign>$</DollarSign>
            <Text16Bold>{(earnings * VALUE_OF_ONE_STAR_IN_USD * 1.1).toFixed(2)}</Text16Bold>
          </EarningContainer>
          <EarningContainer>
            <NetText>Net:</NetText>
            <DollarSign>$</DollarSign>
            <Text22Bold>{(earnings * VALUE_OF_ONE_STAR_IN_USD).toFixed(2)}</Text22Bold>
          </EarningContainer>
        </EarningsContent>
      </CardContent>
    </Card>
  );
};

export default AnalyticsCard;
