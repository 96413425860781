import styled from 'styled-components';
import { Text14Light } from '../../components/utils/Text/Text.styles';

export const PageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-top: 1.6rem;
  padding-inline: 1.6rem;
  padding-bottom: 20rem;

  @media only screen and (min-width: 768px) {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-left: 4.4rem;
`;

export const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const Text = styled(Text14Light)`
  line-height: 2.4rem;
  word-break: break-word;
`;
