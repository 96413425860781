import React, { Fragment, useEffect, useState } from 'react';
import GlobalStyles from '../../styles/globalStyles';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import {
  AcceptAgreementPage,
  CheckoutPage,
  EmailVerificationPage,
  ForbiddenPage,
  ForgotPasswordPage,
  HomePage,
  IdVerificationPage,
  InReviewPage,
  LoginPage,
  PageNotFound,
  PrivacyPolicyPage,
  ProfilePage,
  ResetPasswordPage,
  SignUpPage,
  SplashPage,
  SubscribePage,
  TermsAndConditionsPage,
  UpdateBasicInfoPage,
  UpdatePasswordPage,
  UploadMediaPage,
  WalletPage,
  WithdrawalMethodPage,
  WithdrawPage,
} from '../../pages';
import { selectIsLoggedIn } from '../../store/slices/auth/slice';
import { AuthLayout, PrivateLayout } from '../../layouts';
import { fetchUserInfo } from '../../store/slices/user/asyncThunks';
import {
  ACCEPT_AGREEMENT,
  CHECKOUT,
  EMAIL_VERIFICATION,
  FORBIDDEN,
  FORGOT_PASSWORD,
  HOME,
  ID_VERIFICATION,
  IN_REVIEW,
  LOGIN,
  PRIVACY_POLICY,
  PROFILE,
  RESET_PASSWORD,
  SIGN_UP,
  SUBSCRIBE,
  TERMS_AND_CONDITIONS,
  UPDATE_BASIC_INFO,
  UPDATE_PASSWORD,
  UPLOAD_MEDIA,
  WALLET,
  WITHDRAW,
  WITHDRAWAL_METHOD,
} from './routes';
import PrivateOutlet from '../PrivateOutlet/PrivateOutlet';
import InfluencerRequestOutlet from '../InfluencerRequestOutlet/InfluencerRequestOutlet';
import { INFLUENCER_REQUEST_STATUS, USER_ROLE } from '../../utils/constants';
import { useIntercom } from 'react-use-intercom';
import { selectUser } from '../../store/slices/user/slice';

const App = () => {
  const dispatch = useDispatch();
  const { boot, hardShutdown } = useIntercom();
  const user = useSelector(selectUser);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [minimumDurationPassed, setMinimumDurationPassed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setMinimumDurationPassed(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      boot({
        name: user.name,
        email: user.email,
        userId: user.id,
        createdAt: user.createdAt,
      });
    } else {
      hardShutdown();
    }
  }, [user, boot, hardShutdown]);

  return (
    <Fragment>
      <GlobalStyles />
      <ToastContainer newestOnTop={true} autoClose={3500} />
      {minimumDurationPassed && isLoggedIn !== null ? (
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path={LOGIN} element={<LoginPage />} />
            <Route path={SIGN_UP} element={<SignUpPage />} />
            <Route path={FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
            <Route path={EMAIL_VERIFICATION} element={<EmailVerificationPage />} />
            <Route path={RESET_PASSWORD} element={<ResetPasswordPage />} />
          </Route>
          <Route element={<PrivateLayout hideNavigation />}>
            <Route element={<PrivateOutlet roles={[USER_ROLE.INFLUENCER_REQUEST]} />}>
              <Route element={<InfluencerRequestOutlet allowedStatuses={[]} />}>
                <Route path={UPDATE_PASSWORD} element={<UpdatePasswordPage />} />
              </Route>
              <Route element={<InfluencerRequestOutlet allowedStatuses={[]} />}>
                <Route path={SUBSCRIBE} element={<SubscribePage />} />
              </Route>
              <Route element={<InfluencerRequestOutlet allowedStatuses={[]} />}>
                <Route path={CHECKOUT} element={<CheckoutPage />} />
              </Route>
              <Route element={<InfluencerRequestOutlet allowedStatuses={[INFLUENCER_REQUEST_STATUS.SUBSCRIBED]} />}>
                <Route path={ID_VERIFICATION} element={<IdVerificationPage />} />
              </Route>
              <Route element={<InfluencerRequestOutlet allowedStatuses={[INFLUENCER_REQUEST_STATUS.IDENTIFIED]} />}>
                <Route path={UPDATE_BASIC_INFO} element={<UpdateBasicInfoPage />} />
              </Route>
              <Route element={<InfluencerRequestOutlet allowedStatuses={[INFLUENCER_REQUEST_STATUS.ONBOARDED]} />}>
                <Route path={UPLOAD_MEDIA} element={<UploadMediaPage />} />
              </Route>
              <Route element={<InfluencerRequestOutlet allowedStatuses={[INFLUENCER_REQUEST_STATUS.MEDIA_UPLOADED]} />}>
                <Route path={ACCEPT_AGREEMENT} element={<AcceptAgreementPage />} />
              </Route>
              <Route element={<InfluencerRequestOutlet allowedStatuses={[INFLUENCER_REQUEST_STATUS.COMPLETED]} />}>
                <Route path={IN_REVIEW} element={<InReviewPage />} />
              </Route>
            </Route>
          </Route>

          <Route element={<PrivateLayout />}>
            <Route element={<PrivateOutlet roles={[USER_ROLE.INFLUENCER]} />}>
              <Route path={HOME} element={<HomePage />} />
              <Route path={WALLET} element={<WalletPage />} />
              <Route path={WITHDRAW} element={<WithdrawPage />} />
              <Route path={WITHDRAWAL_METHOD} element={<WithdrawalMethodPage />} />
              <Route path={PROFILE} element={<ProfilePage />} />
              <Route path={TERMS_AND_CONDITIONS} element={<TermsAndConditionsPage />} />
              <Route path={PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
            </Route>
          </Route>

          <Route path={FORBIDDEN} element={<ForbiddenPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      ) : (
        <SplashPage />
      )}
    </Fragment>
  );
};

export default App;
