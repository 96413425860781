import React, { useRef } from 'react';
import { Content, PageContainer, ProfileInfo, ProfilePhotoAvatar, Title } from './ProfilePage.styles';
import { Text14Light } from '../../components/utils/Text/Text.styles';
import { SettingsButton, SingleFieldInput } from '../../components';
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from '../../components/App/routes';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/slices/auth/asyncThunks';
import { ReactComponent as LogOutIcon } from '../../assets/icons/logout.svg';
import { selectUser } from '../../store/slices/user/slice';

const ProfilePage = () => {
  const changePasswordModalRef = useRef();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <PageContainer>
      <Content>
        <Title>Settings</Title>
        <ProfilePhotoAvatar>
          <img src={user.profilePhoto} alt="profile" />
        </ProfilePhotoAvatar>
        <ProfileInfo>
          <Text14Light>Profile</Text14Light>
          <SingleFieldInput name="Email" value={user.email} />
        </ProfileInfo>
        <ProfileInfo>
          <Text14Light>Setting</Text14Light>
          <SettingsButton
            title="Change Password"
            onClick={() => {
              changePasswordModalRef.current.show();
            }}
          />
          <SettingsButton title="Privacy Policy" onClick={() => navigate(`/${PRIVACY_POLICY}`)} />
          <SettingsButton title="Terms & Conditions" onClick={() => navigate(`/${TERMS_AND_CONDITIONS}`)} />
          <SettingsButton title="Logout" icon={<LogOutIcon />} onClick={() => dispatch(logout())} />
        </ProfileInfo>
      </Content>
    </PageContainer>
  );
};

export default ProfilePage;
