import React from 'react';
import { ButtonContainer, IconWrapper, Title } from './SettingsButton.styles';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/chevron_right.svg';

const SettingsButton = ({ title, icon, onClick }) => {
  return (
    <ButtonContainer onClick={onClick}>
      <Title>{title}</Title>
      <IconWrapper>{icon ? icon : <ArrowRightIcon color="#AD87FF" />}</IconWrapper>
    </ButtonContainer>
  );
};

export default SettingsButton;
